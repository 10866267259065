import { useIntl } from "react-intl";
import I18n from "../../i18n/i18n";

export const EnumUnit = [
    { label: "Box", id: 15 },
    { label: "Pack", id: 1 },
    { label: "Dozen", id: 2 },
    { label: "Roll", id: 3 },
    { label: "Unit", id: 4 },
    { label: "Piece", id: 5 },
    { label: "Carton", id: 6 },
    { label: "Bag", id: 7 },
    { label: "Kilogram", id: 8 },
    { label: "Ton", id: 14 },
    { label: "Gallon", id: 9 },
    { label: "Gram", id: 10 },
    { label: "Liter", id: 11 },
    { label: "Meter", id: 12 },
    { label: "Other", id: 13 }
]

export const GetUnitLabel = (itemData) => {

    if (itemData.unitType == 13 && itemData.fieldsDescriptions) {
        return JSON.parse(itemData.fieldsDescriptions)["unitType"]
    }
    return FindUnit(itemData.unitType)?.label
}

export const FindUnit = (id) => {
    const intl = useIntl()
    const unit = EnumUnit.find(x => x.id == id)

    if (unit)
        return { ...unit, label: intl.formatMessage({ id: unit.label.replace("&", "").replace(/\s/g, "").toLowerCase() }) }

    return { label: '' }
}

