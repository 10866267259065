import { Autocomplete } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { EnumCurrency, GetCurrency } from '../Enums/EnumCurrency';
import BTextField from '../atomic/BTextField';
import BAutoComplete from './BAutoComplete';

export default function FieldPriceCurrency(props) {
    const {
        disabled,
        onChange,
        value,
        options } = props

    const intl = useIntl()

    const getFullLabel = (option) => {
        {
            try {
                let region = option?.region ?? option?.id
                if (region == undefined)
                    region = option

                if (region) {
                    var currency = GetCurrency(region)
                    
                    if(currency == undefined){
                        return ""
                    }

                    let cu_label = currency.label
                    let countryLabel = currency.countryLabel

                    return `${cu_label} (${countryLabel})`
                }
                else
                    return ""
            } catch (error) {
                console.log(error, option)
            }
        }
    }

    return (
        <BAutoComplete
            id={"priceCurrency"}
            disabled={disabled}
            value={value}
            getOptionLabel={(option) => getFullLabel(option)}
            onChange={(e, selected) => onChange && onChange(selected)}
            options={options ?? EnumCurrency}
            {...props}
        />
    )
}
