import { createSlice } from '@reduxjs/toolkit';
import { EnumCurrency } from "../../components/Enums/EnumCurrency";
import English from "../../i18n/languages/en.json";
import { userLocale } from '../Storage/MyStorage';


const localeSlice = createSlice({
    name: "locale",
    initialState: {
        dir: 'ltr',
        language: userLocale(),
        messages: English,
        currency: userLocale() == 'fa' ? EnumCurrency.find(x => x.id == "Toman") : EnumCurrency.find(x => x.id == "USD")
    },
    reducers: {
        setMessages: (state, action) => {
            state.messages = action.payload.messages;
            state.dir = action.payload.dir
        },
        setLocale: (state, action) => {
            state.language = action.payload
            userLocale(state.language)
        },
        setCurrency: (state, action) => {
            state.currency = EnumCurrency.find(x => x.id == action.payload)
        },
    },

})

export const { setLocale, setCurrency, setMessages } = localeSlice.actions
export const localeReducer = localeSlice.reducer