import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

export default function LoadingApp() {
    return (
        <Box display={'flex'} flexDirection={'column'} sx={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
            <Typography padding={1}>
                Loading...
            </Typography>
        </Box>
    )
}
