import dayjs from "dayjs";
import { GET_ALL_CHATS, APPEND_MESSAGE, GET_CHAT_MESSAGES, APPEND_CHAT, LOADING_MESSAGE, CLEAR_ONSITE } from "../Actions/MessageActions"


const initialState = {
    allMessages: [],
    allChats: [],
    chatMessages: [],
    myChats: [],
    blockList: [],
    newMessage: {},
    loading: true
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_CHAT_MESSAGES:
            return {
                ...state,
                chatMessages: action.payload
            }

        case GET_ALL_CHATS:
            return {
                ...state,
                allChats: action.payload
            }

        case LOADING_MESSAGE:
            return {
                ...state,
                loading: action.payload
            }
        case APPEND_MESSAGE:
            const { chatId, newMessage } = action.payload;

            return {
                ...state,
                allChats: state.allChats.map(chat =>
                    chat.id === chatId
                        ? { ...chat, mesages: [...chat.mesages, newMessage] }
                        : chat)
            }

        case APPEND_CHAT:
            const { chatModel } = action.payload;
            return {
                ...state,
                allChats: [...state.allChats, chatModel]
            }

        case CLEAR_ONSITE:
            const {  } = action.payload;

            return {
                ...state,
                allChats: state.allChats.filter(x => x.id != 'onsite')
            }

        default: return state
    }

}