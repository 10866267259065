import { GET_USERS } from '../Types/types'
import { GET_USER_PROFILE, LOADING_USER } from '../Types/userTypes'

const initialState = {
    userProfile: {},
    users: [],
    loading: true
}

export default function (state = initialState, action) {

    switch (action.type) {

        case LOADING_USER:
            return {
                ...state,
                loading: action.payload
            }

        case GET_USER_PROFILE:
            return {
                ...state,
                userProfile: action.payload,
                loading: false

            }
        default: return state
    }

}