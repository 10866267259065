import { Button, ButtonProps, CircularProgress } from "@mui/material"
import { Box } from "@mui/system";
import React from "react";

interface LoadingButtonProps extends ButtonProps {
    loading: boolean
}

export default function LoadingButton(props: LoadingButtonProps) {
    return (
        <Button
            {...props}
            disabled={props.loading || props.disabled}
        >
            {props.loading ? (
                <Box sx={{ p: .5 }}>
                    <CircularProgress color={'primary'} size={'1rem'} />
                </Box>

            ) : (
                props.children
            )}

        </Button>
    )
}