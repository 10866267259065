export function userToken(token) {
    
    if(token == false)
    {
        localStorage.removeItem("userToken")
    }
    else if(token)
    {
        localStorage.setItem("userToken" , token)
    } 

    return localStorage.getItem("userToken")
}

export function userRefreshToken(RefreshToken) {
    
    if(RefreshToken == false)
    {
        localStorage.removeItem("userRefreshToken")
    }
    else if(RefreshToken)
    {
        localStorage.setItem("userRefreshToken" , RefreshToken)
    } 

    return localStorage.getItem("userRefreshToken")
}

export function userLocale(locale) {
    
    if(locale == false)
    {
        localStorage.removeItem("userlocale")
    }
    else if(locale)
    {
        localStorage.setItem("userlocale" , locale)
    } 

    var lang = localStorage.getItem("userlocale") 
    return lang == null ? (window.location.hostname.includes(".ir") ? "fa" : 'en') : lang
}