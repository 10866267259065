import { FormLabel, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import I18n from "../../i18n/i18n";
import numberConverter from "../../_Helper/NumberConverter";

export default function BTextField(props) {

    const intl = useIntl()
    const labelid = props.labelid ?? props.id

    function error() {
        if (props.error) {
            if (typeof props.error == 'function') {
                return props.error(props.id, props.raw ? props.value : (props.value ?? (props.type === 'number' ? NaN : "")))
            }
            else if(typeof props.error == 'boolean'){
                return props.error
            }
            else {
                return props.error[labelid]
            }
        }
        return false
    }
    const showHelperText = () => {

        const defaultError = `${labelid}.${props.errorPostfix ?? "error"}`
        var id = props.serviceType ? `${props.serviceType}.${labelid}.${props.errorPostfix ?? "error"}` : defaultError

        if (props.serviceType) {
            if (intl.messages[id] == undefined) {
                id = defaultError
            }
        }
        const helpertext = intl.messages[id] != undefined ? intl.formatMessage({ id: id }) : id

        return helpertext ?? ''
    }

    const setNumberValue = (value) => {
        var _value = value.replace(/,/g, '')

        _value = numberConverter(_value)
        if (isNaN(_value))
            return NaN

        const inputNumber = _value.replace(/\D/g, '');
        const numericValue = Number(inputNumber);

        props.onChange(numericValue);
    }

    const getValue = () => {
        return props.value === undefined ? '' : props.value ? props.value.toLocaleString() : "0".toLocaleString()
    }
    const _props = { ...props, inputProps: { ...props.inputProps, id: props.id ?? props.labelid } }

    const handleChange = (event) => {
        const modifiedValue = numberConverter(event.target.value)
        const modifiedEvent = { ...event, target: { value: modifiedValue, id: event.target.id , name : event.target.name } };

        props.onChange && props.onChange(modifiedEvent);
    };

    return (
        <>
            {props.mylabel ?? ((props.id && props.showlabel) || props.labelid &&
                <FormLabel required={props.required} {...props.labelProps}>
                    <I18n id={labelid} />
                    {props.inEn && <I18n id={"inEnglish"} />}
                </FormLabel>)
            }

            {props.textfieldComponent ??
                (props.type == 'number' && props.thousandSeparator) ?
                <TextField
                    {..._props}
                    type="text"
                    error={error()}
                    onChange={e => setNumberValue(e.target.value)}
                    value={getValue()}
                    helperText={(props.showHelperText ?? true) && (error() && (props.helperText ?? showHelperText()))}
                />
                :
                <TextField
                    {..._props}
                    onChange={handleChange}
                    error={error()}
                    helperText={(props.showHelperText ?? true) && (error() && (props.helperText ?? showHelperText()))}
                />
            }
        </>
    )
}