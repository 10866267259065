import { CheckCircleOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import I18n from "../../../i18n/i18n";
import Logo from "../../../components/atomic/Logo";

const SubscribeSccusful = (props) => {
  const { state } = useLocation();
  const { daysLeft } = state;

  const navigate = useNavigate();

  return (
    <Container maxWidth={"sm"} sx={{ marginTop: 10 }}>
      <Card sx={{ border: "2px solid #1c39bb" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component={Link}
              sx={{ display: "flex", alignItems: "center", marginBlock: 3 }}
              to="/"
            >
              <Logo text />
            </Box>

            <CheckCircleOutline color="success" style={{ fontSize: 120 }} />

            <Typography variant="h4" align="center" gutterBottom>
              <I18n id="success" /> !
            </Typography>

            <Typography variant="h5" align="center" style={{ marginTop: 20 }}>
              {props.user.userProfile.firstName}{" "}
              <I18n id="Dear" /> {" "}
              <I18n id="memberCollection" />
            </Typography>
            <Typography my={3} variant="body1" align="center" gutterBottom>
              <I18n id="DaysSubscription" />: {daysLeft} <I18n id="dayLeft" />
            </Typography>

            <Button
              onClick={() => {
                navigate("/en/dashboard");
              }}
            >
              <I18n id="backtomain" />{" "}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(SubscribeSccusful);
