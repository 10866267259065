import { Autocomplete } from '@mui/material';
import React from 'react';
import { useIntl as myIntl } from 'react-intl';
import { getOptionLabel as GlobalOption } from "../../_Helper/AutoCompleteGetLabel";
import BTextField from '../atomic/BTextField';

export default function BAutoComplete(props) {

  const {
    disabled,
    value,
    getOptionLabel,
    onChange,
    options,
    placeholder,
    required,
    renderInputProps,
    renderOption,
    getOptionDisabled,
    useIntl } = props

  const intl = myIntl()

  return (
    <>
      <Autocomplete
        onBlur={props.onBlur}
        size="small"
        disabled={disabled}
        value={value ?? ""}
        getOptionLabel={(option) => getOptionLabel ? getOptionLabel(option) : ((useIntl ?? false) ? GlobalOption(options, option, intl) : GlobalOption(options, option)) ?? option}
        onChange={(e, selected) => onChange(selected)}
        options={options}
        renderInput={(params) => (
          <BTextField
            size="small" {...params}
            placeholder={placeholder ?? intl.formatMessage({ id: "choose" })}
            showlabel={props.showlabel ?? false}
            required={required}
            serviceType={props.serviceType}
            error={props.error}
            {...renderInputProps}
            id={props.id ?? props.label} />
        )}
        renderOption={renderOption}
        getOptionDisabled={getOptionDisabled}
      />
    </>
  )
}
