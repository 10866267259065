import { FormLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getOptionLabel } from '../../_Helper/AutoCompleteGetLabel';
import { countries, getCities } from '../../_Helper/CountryStateCity';
import I18n from '../../i18n/i18n';
import BAutoComplete from './BAutoComplete';

export default function FieldCitySelector(props) {
    const {
        labelComponent,
        label,
        serviceType,
        required,
        disabled,
        onChange,
        value,
        countryCode,
        stateCode } = props

    const [cities, setCities] = useState([]);

    useEffect(() => {
        fetch('/data/city.json').then(data =>
            data.json().then(x => {
                setCities(x)
            }))
    }, [])

    return (
        <>
            {labelComponent ? labelComponent :
                <FormLabel required={required ?? true}>
                    <I18n id={label} serviceType={serviceType} />
                </FormLabel>}

            <BAutoComplete
                disabled={disabled}
                value={value}
                onChange={(e, selected) => onChange(selected)}
                options={getCities(cities, countryCode, stateCode)}
                {...props}
                required={required ?? true}
            />
        </>
    )
}
