import { useDispatch } from "react-redux";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ErrorPage from "./Pages/404Error/404";
import RFQuotation from "./Pages/Home/rfq-questions/RFQuotation";
import { setLocale } from "./redux/Slices/LocaleSlice";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import myapi from "./API/MyAPI";
import { lazy, Suspense, useEffect } from "react";
import { Box } from "@mui/material";
import LoadingApp from "./LoadingApp";

const UserDashboardLayout = lazy(() => import('./Layout/UserLayout/UserDashboardLayout'));
const AdminDashboardLayout = lazy(() => import('./Layout/AdminLayout/AdminDashboardLayout'));
const BaseLayout = lazy(() => import('./Layout/BaseLayout/BaseLayout'));

export default function LocaleURL(props) {

    const { locale } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const intl = useIntl()

    const routeParams = useParams();

    if (props.locale) {
        dispatch(setLocale(props.locale))
    }

    return (
        <Box sx={{ marginBottom: { xs: "20px", sm: 0 } }}>
            <Helmet>
                <title>{intl.formatMessage({ id: "seo.title" })}</title>
                <meta property="og:title" content={intl.formatMessage({ id: "seo.title" })} />
                <meta property="og:description" content={intl.formatMessage({ id: "seo.desc" })} />

                <meta name="twitter:title" content={intl.formatMessage({ id: "seo.title" })} />
                <meta name="twitter:description" content={intl.formatMessage({ id: "seo.desc" })} />
            </Helmet>
            <Suspense fallback={<LoadingApp />}>
                <Routes>
                    <Route index path="/*" element={<BaseLayout />} />
                    <Route exact path="/dashboard/*" element={<UserDashboardLayout />} />
                    <Route exact path="/dashboard/admin/*" element={<AdminDashboardLayout />} />
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
            </Suspense>
        </Box>
    )
};