export const getOptionLabel = (arrays, option, intl, notProccess) => {
    let label = ""
    if (option == undefined)
        return ""
    
    if (option.id != undefined) {
        label = arrays.find(x => x.id === option.id)?.label
    }
    else {
        label = arrays.find(x => x.id === option)?.label
        if (label == undefined) {
            label = arrays.find(x => x === option)
        }
    }

    if (intl && label) {
        var preproccess = notProccess ? label :
            label.replace("&", "").replace(/\s/g, "").toLowerCase()

        return intl.formatMessage({
            id: preproccess
        })
    }

    return label ?? ""
}