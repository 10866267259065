import { CheckCircleOutline } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import myapi from "../../../API/MyAPI";
import I18n from "../../../i18n/i18n";
import Logo from "../../../components/atomic/Logo";

const PaymentStatus = () => {
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [succusfull, setSuccusfull] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    myapi
      .get(
        `/Payment/CallBackZarinP?paymentId=${queryParams.get(
          "paymentId"
        )}&Authority=${queryParams.get("Authority")}&Status=${queryParams.get(
          "Status"
        )}`
      )
      .then((res) => {
        if (res.data.statusCode == 200) {
          setSuccusfull(true);
          setPaymentData(res.data.data);
        } else {
          setSuccusfull(false);
        }
      })
      .finally((e) => {
        setLoading(false);
      });
  }, []);

  return (
    <Container maxWidth={"sm"} sx={{ marginTop: 10 }}>
      <Card sx={{ border: "2px solid #1c39bb" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component={Link}
              sx={{ display: "flex", alignItems: "center", mr: 3 }}
              to="/"
            >
              <Logo text />
            </Box>
            {loading ? <CircularProgress /> :
              paymentData.verified ?
                <>
                  <CheckCircleOutline color="success" style={{ fontSize: 120 }} />
                  <Typography variant="h4" align="center" gutterBottom>
                    <I18n id="PaymentSuccuss" /> !
                  </Typography>
                </>
                :
                <>
                  <ErrorOutlineIcon color="error" style={{ fontSize: 120 }} />
                  <Typography variant="h4" align="center" gutterBottom>
                    <I18n id="PaymentFailed" /> !
                  </Typography>
                </>
            }

            <Typography variant="body1" align="center" gutterBottom>
              <I18n id="PaymentID" /> {queryParams.get("paymentId")}
            </Typography>

            <Button
              onClick={() => {
                navigate("/en/dashboard");
              }}
            >
              <I18n id="backtomain" />{" "}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PaymentStatus;
