import { Typography } from '@mui/material'
import React from 'react'

export default function Logo(props) {
    return (
        <>
            <img height={40} width={40} src={"/logo.png"} alt={"Baramen Logo"} />

            {props.text &&
            <Typography sx={{ fontFamily: 'Helvetica', fontSize: 22, color: 'primary.main', fontWeight: 'bold', pl: 1 }}>
                Baramen
            </Typography>}
        </>
    )
}
