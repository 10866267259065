import axios from 'axios'
import { userRefreshToken, userToken } from '../redux/Storage/MyStorage'
import { config } from "./baseurl_Config"


const myapi = axios.create({
    baseURL: config,
})

myapi.interceptors.request.use((config) => {
    const token = userToken()
    if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
    }
    return config;
},
    (error) => {
        return Promise.reject(error);
    }
);

myapi.interceptors.response.use((res) => {
    return res;
},
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/Auth/LoginUser" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    var model = {
                        AccessToken: userToken(),
                        RefreshToken: userRefreshToken()
                    }
                    const rs = await myapi.post("/Auth/Refresh", model)

                    const { refreshToken, token } = rs.data.data;

                    userRefreshToken(refreshToken)
                    userToken(token)

                    return myapi(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);


export default myapi