import { countries } from "../../_Helper/CountryStateCity";

function removeDuplicates(arr) {
    const ids = arr.map(({ id }) => id);
    const filtered = arr.filter(({ id }, index) =>
        !ids.includes(id, index + 1));

    return filtered
}

function selectCurrencies() {
    return countries.map(x => ({ id: x.currency, label: x.currency, country: x.id ,countryLabel : x.label }))
}

export const EnumCurrency = removeDuplicates(selectCurrencies()).sort((a, b) => a.id.localeCompare(b.id))
export const GetCurrency = (currencyId) => EnumCurrency.find(x => x.id == currencyId)