import React from "react";
import pic from "../../../src/assets/logo.png";
import "./style.css";

const ErrorPage = () => {
  return (
    <>
      <section>
        
        <div class="bg-img fzf-image">
          <div class="container-fluid">
            <div class="row main-text">
              Baramen.Com
              <img src={pic} alt="logo" class="logo-img mt-1" />
            </div>
            <div class="text-center">
              <p class="error">404</p>
              <h3 style={{ color: "#2e6da0" }}>
                Sorry!
                <br />
                The Page You're Looking For Was Not Found
              </h3>
            </div>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-4 text-center">
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-6">
                    <button class="mt-4 back" type="button" href="#">
                      Back to home
                    </button>
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </div>
              <div class="col-4"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErrorPage;
