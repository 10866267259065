import { combineReducers } from 'redux'
import AlertReducers from './AlertReducers'
import authReducers from './AuthReducers'
import userReducers from './UserReducers'
import StoreReducers from './StoreReducers'
import CategoryReducers from './CategoryReducers'
import WalletReducers from './WalletReducers'
import MessagesReducer from './MessagesReducer'
import {localeReducer} from "../Slices/LocaleSlice";
import NotificationReducers from './NotificationReducers'

export default combineReducers({
  user: userReducers,
  auth : authReducers,
  alertinfo : AlertReducers,
  store : StoreReducers,
  category : CategoryReducers,
  wallet : WalletReducers,
  messages : MessagesReducer,
  notifs: NotificationReducers,
  locale: localeReducer
})