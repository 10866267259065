export enum EnumServiceType {
    FullPackage = 0,
    InternalTransport = 1,
    PackageService = 2,
    WarehouseService = 3,
    InspectService = 4,
    GlobalTransport = 5,
    Customs = 6,
    All = 7,
    ItemRFQ = 8,
    MoneyTransfer = 9,
    TradeTrustee = 10,
    Insurance = 11,
    Consult = 12,
    Alibaba = 13
}