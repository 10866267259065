import { DEPOSIT, GET_MY_WALLET, GET_TRANSACTIONS, LOADING_WALLET } from "../Types/walletTypes"

const initialState = {
    userWallet: {},
    transactions: [],
    loading: false
}

export default function (state = initialState, action) {

    switch (action.type) {

        case LOADING_WALLET:
            return {
                ...state,
                loading: action.payload
            }

        case GET_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload,
                loading: false

            }
        case GET_MY_WALLET:
            return {
                ...state,
                userWallet: action.payload,
                loading: false

            }

        default: return state
    }

}