export const NotifTypes = {
    All: "All",
    Chats: "Chats",
    UserOrders: "UserOrders",
    SellerOrders: "SellerOrders",
    SellerOrdersStatusChanged: "SellerOrdersStatusChanged",
    MyRFQStatusChanged: "MyRFQStatusChanged",
    RFQOffers: "RFQOffers",
    MyOffersRFQStatusChanged: "MyOffersRFQStatusChanged",
    MyServicesStatusChanged: "MyServicesStatusChanged",
    ServicesOffers: "ServicesOffers",
    MyOffersServicesStatusChanged: "MyOffersServicesStatusChanged",
    RemoveEntity: "RemoveEntity"
}