import countriesData from "../assets/json_data/country.json";

export const otherState = {
    "name": "Other",
    "isoCode": "UN",
    "flag": "un",
    "phonecode": "0",
    "currency": "",
    "latitude": "",
    "longitude": "",
    "timezones": [
        {
            "zoneName": "",
            "gmtOffset": 0,
            "gmtOffsetName": "",
            "abbreviation": "",
            "tzName": ""
        }
    ]
}

export const anyCountry = {
    "name": "Any Country",
    "isoCode": "UN",
    "flag": "un",
    "phonecode": "0",
    "currency": "",
    "latitude": "",
    "longitude": "",
    "timezones": [
        {
            "zoneName": "",
            "gmtOffset": 0,
            "gmtOffsetName": "",
            "abbreviation": "",
            "tzName": ""
        }
    ]
}
export const FindCountry = (id) =>{
    return countries.find(x => x.id == id)
}

export const countries = countriesData.map(({ name, isoCode, phonecode, flag, currency }) => {
    return {
        id: isoCode,
        label: name,
        phonecode: phonecode,
        flag: flag,
        currency: currency,
    };
})

export const getStates = (statesData , countryCode) => {
    if (!countryCode)
        return [];
    // get data from file or cache
    let _states = statesData.filter((value) => {
        return value.countryCode === countryCode;
    });
    
    _states = [..._states , otherState]

    return _states.map(({ name, isoCode }) => {
        return {
            id: isoCode,
            label: name,
        };
    });
}

export const getCities = (cities, countryCode, stateCode) => {
    if (!stateCode)
        return [];
    if (!countryCode)
        return [];

    let _cities = cities.filter((value) => {
        return value.countryCode === countryCode && value.stateCode === stateCode;
    })

    _cities = [..._cities , otherState]

    return _cities.map(({ name }) => {
        return {
            id: name,
            label: name,
        };
    });
}