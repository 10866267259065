import { ERROR, INFO, SUCCESS } from '../Types/types'

const initialState = {
    alertdetail: {
        text: "",
        title: "",
    },
    type: "",
    loading: true
}

export default function (state = initialState, action) {

    let type = ""
    switch (action.type) {

        case ERROR:
            type = "error"
            break;
        case INFO:
            type = INFO
            break;
        case SUCCESS:
            type = SUCCESS
            break;
    }

    if (type) {
        return {
            ...state,
            alertdetail: action.payload,
            type: type,
            loading: false
        }
    }

    return state

}