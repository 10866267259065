import { NotifTypes } from "../Types/notifTypes"


const initialState = {
    Chats: { count: 0, entities: [] },
    // UserOrders: { count: 0, entities: [] },

    // SellerOrders: { count: 0, entities: [] },
    // SellerOrdersStatusChanged: { count: 0, entities: [] },

    // MyRFQStatusChanged: { count: 0, entities: [] },
    // RFQOffers: { count: 0, entities: [] },
    // MyOffersRFQStatusChanged: { count: 0, entities: [] },

    // MyServicesStatusChanged: { count: 0, entities: [] },

    // ServicesOffers: { count: 0, entities: [] },

    UnreadNotifs: [],
}

export default function (state = initialState, action) {

    switch (action.type) {
        case NotifTypes.All:
            return {
                ...state,
                UnreadNotifs : action.payload
            }

        case NotifTypes.RemoveEntity:
            const newState = {
                ...state,
                UnreadNotifs : state.UnreadNotifs.filter(x => x.id != action.payload)
            }

            return newState

        default: return state
    }

}