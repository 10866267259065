import myapi from '../../API/MyAPI'

import { ERROR } from '../Types/types'

export const GET_ALL_CHATS = 'GET_ALL_CHATS'
export const GET_MY_CHATS = 'GET_MY_CHATS'
export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES'
export const LOADING_MESSAGE = 'LOADING_MESSAGE'
export const APPEND_MESSAGE = 'APPEND_MESSAGE';
export const APPEND_CHAT = 'APPEND_CHAT';
export const CLEAR_ONSITE = 'CLEAR_ONSITE';

export const appendMessage = (chatId, newMessage) => ({
    type: APPEND_MESSAGE,
    payload: { chatId, newMessage },
});

export const appendChat = (chatModel) => ({
    type: APPEND_CHAT,
    payload: { chatModel },
});

export const clearOnSite = () => ({
    type: CLEAR_ONSITE,
    payload: {  },
});


export const GetMyChats = () => async dispatch => {

    try {
        dispatch({
            type: LOADING_MESSAGE,
            payload: true
        })
        myapi.get(`/Messages/GetMyChats`).then(data => {
            dispatch({
                type: GET_ALL_CHATS,
                payload: data.data.data
            })
            dispatch({
                type: LOADING_MESSAGE,
                payload: false
            })
        })

    }
    catch (e) {
        dispatch({
            type: ERROR,
            payload: e,
        })
    }

}