import myapi  from '../../API/MyAPI'
import { ERROR } from '../Types/types'
import { GET_ALL_CATEGORY, LOADING_CATEGORY } from '../Types/categoryTypes'

export const GetAllCategories = () => async dispatch => {

    try {

        dispatch({
            type: LOADING_CATEGORY,
            payload: true
        })

        myapi.get(`/Category/GetAllCategories`).then(data => {
            dispatch({
                type: GET_ALL_CATEGORY,
                payload: data.data.data ?? []
            })
            dispatch({
                type: LOADING_CATEGORY,
                payload: false
            })
        })

    }
    catch (e) {
        dispatch({
            type: ERROR,
            payload: e,
        })
    }

}