import { FormattedMessage, useIntl } from 'react-intl';

const I18n = (props) => {

    const intl = useIntl()

    var id = props.serviceType ? `${props.serviceType}.${props.id}` : props.id

    if (props.serviceType) {
        if (intl.messages[id] == undefined) {
            id = props.id
        }
    }

    return (
        <FormattedMessage
            id={id}
            defaultMessage={props.default ? props.default : props.id}
            values={{
                ...props
            }}
        />
    )
}

export default I18n