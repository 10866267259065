import { ERROR, INFO, SUCCESS } from '../Types/types'

export const Hide = () => {
    return ShowAlert("" , "" , INFO)
}

export const ShowSucced = (text, title) => {
    return ShowAlert(text , title , SUCCESS)
}

export const ShowInfo = (text, title) => {
    return ShowAlert(text , title , INFO)
}

export const ShowError = (text, title) => {
    return ShowAlert(text , title , ERROR)
}

const ShowAlert = (text , title , type) => {
    return({
        type: type,
        payload: {
            text: text,
            title: title
        }
    })
}

