import { LOGIN, LOGIN_STATUS,USER_ROLES } from '../Types/authTypes'

const initialState = {
    token: "",
    userModel: {},
    userRoles: [],
    loading: true,
    loggedIn: false,
    shouldVerify: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case LOGIN_STATUS:
            return {
                ...state,
                loggedIn: action.payload
            }

        case USER_ROLES:
            return {
                ...state,
                userRoles: action.payload
            }

        case LOGIN:
            return {
                ...state,
                token: action.payload
            }
        default: return state
    }

}