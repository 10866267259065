import { EDIT_STORE, GET_ALL_STORES, GET_MY_STORE, LOADING_STORE, REMOVE_STORE, SHOW_REGISTER_MESSAGE } from '../Types/storeTypes'

const initialState = {
    myStore: {
        storeModel: { storeName : '', showStoreData: false },
        companyModel: { companyName: '' , showCompanyData: false }
    },
    store: {},
    sellers: [],
    loading: true,
    show_register_message: false,

}

export default function (state = initialState, action) {

    switch (action.type) {

        case LOADING_STORE:
            return {
                ...state,
                loading: action.payload
            }

        case GET_MY_STORE:
            return {
                ...state,
                myStore: action.payload ?? {
                    storeModel: { storeName : '', showStoreData: false },
                    companyModel: { companyName: '' , showCompanyData: false }
                },
                loading: false
            }

        default: return state
    }

}