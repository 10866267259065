import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Hide } from "../redux/Actions/AlertAction";

const AlertPopup = (props) => {
  const alertProps = props.alertinfo

  const intl = useIntl()


  useEffect(() => {
    if (alertProps.alertdetail.text && alertProps.alertdetail.title) {
      if (alertProps.type.toLowerCase() === "success") {
        toast.success(alertProps.alertdetail.text);
      }
      if (alertProps.type.toLowerCase() === "info") {
        toast.info(intl.formatMessage({id : alertProps.alertdetail.text}));
      }
      if (alertProps.type.toLowerCase() === "error") {
        if (Array.isArray(alertProps.alertdetail.text)) {
          alertProps.alertdetail.text.map(x => {
            toast.error(x, {
              limit: alertProps.alertdetail.text.length
            });
          })
        }
        else {
          toast.error(intl.formatMessage({id : alertProps.alertdetail.text}))
        }
      }
    }
  }, [alertProps.alertdetail]);


  return (
    <ToastContainer
      autoClose={3000}
      toastStyle={{...props.sx}}
      position="top-center" />
  )
};

const mapStateToProps = (state) => ({ alertinfo: state.alertinfo, user: state.user })
export default connect(mapStateToProps, { Hide })(AlertPopup);