import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from "./serviceWorkerResgistration";

const root = ReactDOM.createRoot(document.getElementById('root'));

const tagManagerArgs = {
    gtmId: 'GTM-NKL7G27K'
}

TagManager.initialize(tagManagerArgs)

try {
    root.render(
        <>
            <Provider store={store}>
                <BrowserRouter basename="/">
                    <App />
                </BrowserRouter>
            </Provider>
        </>
    );
} catch (error) {
    console.error(error);
}

reportWebVitals();

if (!window.location.href.includes('/mag')){
    serviceWorkerRegistration.register();
}