import { GET_ALL_CATEGORY, ADD_CATEGORY, EDIT_CATEGORY, REMOVE_CATEGORY, LOADING_CATEGORY } from "../Types/categoryTypes";

const initialState = {
    categories: [],
    loading: true
}

export default function (state = initialState, action) {

    let type = ""
    switch (action.type) {

        case LOADING_CATEGORY:
            return {
                ...state,
                loading: action.payload
            }

        case GET_ALL_CATEGORY:
            return {
                ...state,
                categories: action.payload
            }
    }

    if (type) {
        return {
            ...state,
            alertdetail: action.payload,
            type: type,
            loading: false
        }
    }

    return state

}