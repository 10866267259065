// useWebsocket.js
import * as signalR from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { wsconfig } from '../API/baseurl_Config';
import { MessageProps, UserProps } from '../Types/MessagesTypes';
import { ShowInfo } from '../redux/Actions/AlertAction';
import { appendMessage } from '../redux/Actions/MessageActions';
import { userToken } from '../redux/Storage/MyStorage';

export enum EnumNotificationType {
    All,
    Chats,
    OrdersBuyer,
    OrdersSeller,
    RFQRequestBuyyer,
    RFQResponseSeller,
    ServiceRequestBuyyer,
    ServiceResponseSeller
}

export interface NotificationModel {
    userId: number;
    message: string | null;
    isRead: boolean;
    type: EnumNotificationType;
    rFQId: number | null;
    entityId: number | null;
    createdAt: string;
    userModel: UserProps | null;
    typeStr: string;
}

const useBaramenMessanger = () => {
    const [connected, setConnected] = useState(false);
    const [socket, setSocket] = useState<WebSocket>();
    const [connection, setConnection] = useState<signalR.HubConnection>();

    const dispatch = useDispatch()

    const OnMessageReceived = (messageModel: MessageProps) => {
        dispatch(appendMessage(messageModel.chatId, messageModel));
    }

    const OnNotifReceived = (notifModel : NotificationModel) => {
        dispatch(ShowInfo(`api.notif.${notifModel.typeStr}`, "Notifs"))
    }

    const InitConnection = () => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(wsconfig, {
                accessTokenFactory: async () => {
                    return '' + userToken()
                }
            })
            .build();

        setConnection(newConnection);
    }

    useEffect(() => {
        if (connection) {
            connection.start().then(() => {
                connection.on("OnReceiveMessage", (messageModel: MessageProps) => {
                    OnMessageReceived(messageModel)
                })

                connection.on("OnNotifReceived", (notifModel: NotificationModel) => {
                    OnNotifReceived(notifModel)
                })

                setConnected(true)

            }).catch((error) => {
                setConnected(false)
                console.error(error.message);
            });
        }
    }, [connection]);

    // useEffect(() => {
    //     const ws = new WebSocket(url);

    //     ws.onopen = () => {
    //         setConnected(true);

    //         var connectRequest = {
    //             "msg": "connect",
    //             "version": "1",
    //             "support": ["1", "pre2", "pre1"]
    //         }

    //         ws.send(JSON.stringify(connectRequest))
    //     };

    //     ws.onmessage = (e) => {
    //         setLastMessage(e.data)
    //     }

    //     ws.onclose = () => {
    //         setConnected(false);
    //     };

    //     setSocket(ws);

    //     return () => {
    //         ws.close();
    //     };
    // }, [url]);

    return { connected, socket, InitConnection };
};

export default useBaramenMessanger;
